import { PureComponent, Fragment, MouseEvent, FunctionComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import { WithTranslation as IWithTranslation } from 'react-i18next';
import { appendParamsCurrentPage, PageSection } from '@utils';
import { WEB_LEGACY_LINK } from '@config';
import { trackRegion, getTrackingCityName, getRegionNameTH } from './tracking';
import './style.scss';

interface RegionsProps extends IWithTranslation {
  prefix: string;
  sectionName: PageSection;
  className?: string;
}
interface SectionRegionsProps extends IWithTranslation, RegionsProps {
  regions: RegionsList;
}
interface RegionsQueryResult {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: City;
      };
    }[];
  };
}
interface City {
  path: string;
  name_th: string;
  name_en: string;
  region: string;
}

interface RegionsList {
  north: City[];
  northEast: City[];
  central: City[];
  east: City[];
  west: City[];
  south: City[];
  other: City[];
}

const regionsTitle = {
  th: {
    north: 'ภาคเหนือ',
    northEast: 'ภาคอีสาน',
    central: 'ภาคกลาง',
    east: 'ภาคตะวันออก',
    west: 'ภาคตะวันตก',
    south: 'ภาคใต้',
    other: 'อื่นๆ'
  },
  en: {
    north: 'Northern Region',
    northEast: 'Northeastern Region',
    central: 'Central Region',
    east: 'Eastern Region',
    west: 'Western Region',
    south: 'Southern Region',
    other: 'Other'
  }
};

class SectionRegions extends PureComponent<SectionRegionsProps> {
  handleOnClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => trackRegion(e, this.props.sectionName);

  url(prefix: string, city: City) {
    const cityPageURL = `${WEB_LEGACY_LINK}/${prefix}/${city.path}`;
    const url = appendParamsCurrentPage(cityPageURL);
    return url;
  }

  regionsList = () => {
    const { prefix, regions, sectionName } = this.props;

    return (
      <Fragment>
        {Object.keys(regions)
          .filter((key) => regions[key].length > 0)
          .map((r) => (
            <div
              className={`${sectionName === PageSection.Help ? 'col-6 col-lg-4' : 'col-6 col-md-3 col-lg-2'}`}
              key={r}
            >
              <h4>
                <span className="d-none">เช่ารถ</span>
                {regionsTitle[prefix][r]}
              </h4>
              <ul>
                {regions[r].map((city: City) => {
                  const isAirport = city[`name_${prefix}`].match(/.*Airport/g);
                  const beforeTitleEN = `Car Rental ${isAirport ? 'at ' : 'in '}`;

                  return (
                    <li key={city[`name_${prefix}`]}>
                      <a
                        onClick={(e) => this.handleOnClick(e)}
                        data-event-label={`destination_${getRegionNameTH(r)}_${getTrackingCityName(city[`name_th`])}`}
                        href={this.url(prefix, city)}
                      >
                        {prefix === 'en' ? beforeTitleEN : 'เช่ารถ'}
                        {city[`name_${prefix}`]}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
      </Fragment>
    );
  };

  render() {
    const { t, className } = this.props;
    return (
      <div className={`regions ${className ? className : ''}`}>
        <div className="container">
          <h3>{t('header')}</h3>
          <div className="row">{this.regionsList()}</div>
        </div>
      </div>
    );
  }
}

const Regions: FunctionComponent<RegionsProps> = (props: RegionsProps) => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allMarkdownRemark(
          filter: {
            fields: { collection: { eq: "cities" } }
            frontmatter: { activate: { eq: true }, no_city_page: { ne: true } }
          }
          sort: { fields: [frontmatter___name] }
        ) {
          edges {
            node {
              frontmatter {
                path
                name_th
                name_en
                region
              }
            }
          }
        }
      }
    `}
    render={(data: RegionsQueryResult) => {
      const cities = data.allMarkdownRemark.edges;
      const regions: RegionsList = {
        north: [],
        northEast: [],
        central: [],
        east: [],
        west: [],
        south: [],
        other: []
      };

      cities.forEach((city) => {
        const c = city.node.frontmatter;

        switch (c.region) {
          case 'ภาคเหนือ':
            return regions.north.push(c);
          case 'ภาคอีสาน':
            return regions.northEast.push(c);
          case 'ภาคกลาง':
            return regions.central.push(c);
          case 'ภาคตะวันออก':
            return regions.east.push(c);
          case 'ภาคตะวันตก':
            return regions.west.push(c);
          case 'ภาคใต้':
            return regions.south.push(c);
          default:
            return regions.other.push(c);
        }
      });

      return <SectionRegions {...props} regions={regions} />;
    }}
  />
);

export default withTrans('shares')(Regions);
