import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

export const trackRegion = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sectionName: PageSection) => {
  sendToDataLayer(e, {
    event_category: sectionName === PageSection.Help ? 'content_services_section' : getDefaultEventCategory(sectionName)
  });
};

export const getTrackingCityName = (cityName: string) => {
  return cityName.toLowerCase().replace(' ', '_');
};

export const getRegionNameTH = (regionName: string) => {
  switch (regionName) {
    case 'north':
      return 'ภาคเหนือ';
    case 'northEast':
      return 'ภาคอีสาน';
    case 'central':
      return 'ภาคกลาง';
    case 'east':
      return 'ภาคตะวันออก';
    case 'west':
      return 'ภาคตะวันตก';
    case 'south':
      return 'ภาคใต้';
    default:
      return '';
  }
};
