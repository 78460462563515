import { DefaultProps } from '../interface';
import Regions from '@shares/regions';
import { PageSection } from '@utils';
import './services.scss';
import { FunctionComponent } from 'react';
const json = {
  th: [
    'เกี่ยวกับ Drivehub',

    'Drivehub คือ เว็บไซต์ผู้นำด้านการจองรถเช่า ที่อำนวยความสะดวกทั้งด้านการจองรถ คุณภาพรถ การเลือกรถอย่างอิสระ และการค้นหารถเช่าราคาที่ดีที่สุด',

    'Drivehub ได้รวบรวมบริษัทรถเช่านานาชาติ(ตามเคาน์เตอร์สนามบิน) และบริษัทรถเช่าท้องถิ่นจากทั่วประเทศ มากกว่า 500 บริษัท ไว้ภายในเว็บไซต์' +
      'ซึ่งรถเช่าภายในเว็บไซต์มีมากกว่า 5000 คัน ให้ลูกค้าสามารถเปรียบเทียบและค้นหารถเช่าราคาที่ดีที่สุด ผ่านเว็บไซต์ไดรฟ์ฮับ โดยเราไม่มีนโยบายเก็บค่าธรรมเนียมการจอง หรือค่าการจัดการใดๆทั้งสิ้นจากผู้เช่า' +
      'ทั้งนี้เรายังได้ทำสัญญานโยบายคุณภาพและความปลอดภัยของรถยนต์กับผู้ประกอบการรถเช่าที่เป็นพันธมิตรกับเรา',

    'รถยนต์ทุกคันที่อยู่ในระบบของเราจำเป็นต้องผ่านการตรวจสภาพรถยนต์มาตรฐานสากลทุกๆ 10,000 กิโลเมตร เพื่อให้ผู้เช่ามั่นใจว่าในการเดินทางแต่ละครั้งของคุณจะเป็นการเดินทางที่ปลอดภัย' +
      'เป้าหมายหลักของไดรฟ์ฮับคือเป็นช่องทางการเช่ารถที่สะดวก รวดเร็ว คุ้มค่า ปลอดภัยและง่ายที่สุดสำหรับผู้เช่ารถ Drivehub นอกจากการจองผ่านช่องทาง www.drivehub.com เรามีแผนกบริการลูกค้าสัมพันธ์สามารถติดต่อคุยกับเจ้าหน้าที่ ได้ตลอดเวลาทำการ 08.00 - 22.00น. ทุกวัน',

    'เว็บไซต์ Drivehub มีลูกค้าที่มั่นใจใช้บริการมากถึง 100,000 การเช่าในปี 2018 ที่ผ่านมา',

    'จังหวัดที่ให้บริการ',

    'ด้วยบริการรถเช่ามากกว่า 500 บริษัทเช่ารถจากกว่า 30 จังหวัดทั่วประเทศ'
  ],
  en: [
    'About Drivehub:',

    'Drivehub is the top destination for hassle-free car rental bookings, offering quality vehicles, flexible choices, and the best prices.',

    `What is Drivehub? It's your go-to website for booking rental cars. We've partnered with over 500 rental companies both interbrand providers and local providers across the country. With a selection of over 5000 rental cars on our platform, you're empowered to compare and discover the very best deals through Drivehub. What's more, we're committed to keeping things transparent – no reservation fees, no hidden charges. We've also worked closely with our rental partners to ensure high standards of quality and safety.`,

    'Every car in our fleet undergoes a rigorous international inspection every 10,000 kilometers, ensuring your journey is secure and worry-free. At Drivehub, our mission is simple: to provide you with the most convenient, efficient, and secure car rental experience possible.Our customer service team is available to assist you from 08:00 to 22:00 every day.',

    'Our Drivehub website has catered to over 100,000 satisfied customers in 2018 alone, earning their trust with our reliable services.',

    'Service Areas:',

    `We're proud to serve across more than 30 provinces nationwide, collaborating with over 500 rental companies to bring you the best car rental experience.`
  ]
};
const Services: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const text = json[prefix];
  return (
    <div className="services--help">
      <h2>{text[0]}</h2>
      <p>{text[1]}</p>
      <p>{text[2]}</p>
      <p>{text[3]}</p>
      <p className="mb-2">{text[4]}</p>
      <div className="services-province-available">
        <h2>{text[5]}</h2>
        <p className="mb-2">{text[6]}</p>
        <Regions className="regions--help-center" prefix={prefix} sectionName={PageSection.Help} />
      </div>
    </div>
  );
};

export default Services;
